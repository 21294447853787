import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

export default function Track({
  currentTrack,
  audioRef,
  setDuration,
  progressBarRef
}) {
  const { playlist } = useSelector(state => state.playlist);
  const sharedTrack = playlist[0]?.token ? playlist[0] : null;

  // Usar los valores del track compartido si existe
  const displayTrack = sharedTrack || currentTrack;
  const remainingPlays = sharedTrack ? 
    (sharedTrack.play_limit - sharedTrack.plays) : 
    (currentTrack.play_limit - currentTrack.plays);

  const onLoadedMetadata = () => {
    const seconds = audioRef.current.duration;

    setDuration(seconds);
    progressBarRef.current.max = seconds;
  }

  return (
    <>
      <div className='flex items-center'>
        <audio
          src={displayTrack.url}
          ref={audioRef}
          onLoadedMetadata={onLoadedMetadata}
        />
        <div className='flex gap-4 items-center'>
          <div
            className='w-[52px] h-[52px] bg-cover rounded'
            style={{ backgroundImage: `url(${displayTrack.cover_url})` }}>
          </div>
          <div className='flex flex-col gap-0.5'>
            <div className='flex flex-col grow'>
              <div className='w-full whitespace-nowrap overflow-hidden relative'>
                <span className='text-sm text-white inline-block'>
                  {displayTrack.name}
                </span>
              </div>
              <span className='text-sm text-neutral-silver-200'>
                {displayTrack?.authors?.join(', ')}
              </span>
              {displayTrack.play_limit && (
                <span className='text-xs text-neutral-silver-300'>
                  {remainingPlays} reproducciones restantes
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}