import Button from 'components/Button';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getFreeTrialDays, format } from 'utils/helpers';

export default function UpdateSubscription({ plan, account, handleCancel, isLoading }) {
  const { t } = useTranslation();
  const [desc, setDesc] = useState()

  useEffect(()=>{
    if(plan && account){
      setDesc(plan?.name.split(' ').pop())
    }
  },[plan,account])

  return (
    <>
      {plan && (
        <>
          <div className='flex flex-col gap-3 max-w-[480px] w-full mb-6'>
            <div className='flex'>
              <div className='font-bold grow'>Plan</div>
            </div>
            <div className='bg-neutral-black p-6 rounded-2xl'>
              <div className='text-lg font-semibold'>{t(`plans.${plan.billing_frequency}.title.${plan.name}`)}</div>
              <div className='text-sm text-neutral-silver-300 mb-3'>{t(`plans.${plan.billing_frequency}.description`)}</div>
              <span className='font-thunder text-2xl uppercase'>
              {plan.pricing_data.currency}
              </span>
              <span className='font-thunder text-4xl'>
                {plan.pricing_data.currency} {(plan.pricing_data.price / plan.billing_frequency).toLocaleString('es-AR')}
              </span>
              
              <span>
                / {t(`plans.${plan.billing_frequency}.monthly`)}
              </span>
              {
            desc !== 'trial'?
            <div className='text-sm text-neutral-200'>{t(`plans.${plan.billing_frequency}.monthly_desc`,{price:plan.pricing_data.price, currency:plan.pricing_data.currency})}</div>
            :
            <div className='text-sm text-neutral-200'>{t(`plans.1.monthly_desc_trial`)}</div>
            }
            </div>
          </div>
          <div className='flex flex-col gap-3 max-w-[480px] w-full'>
            <div className='font-bold grow'>{t('account.payment_method')}</div>
            <div className='bg-neutral-black p-6 rounded-2xl'>
              <div className='text-lg font-semibold'>{t('account.credit_debit')}</div>
              <div className='text-sm text-neutral-silver-300 mb-3'>
                {t('account.payment_detail')}
                {plan.payment_method === 'mercadopago' ? ' Mercado Pago' : ' Stripe'}
              </div>
              <div className='mt-3 flex gap-1 items-baseline'>
                <ul className='list-disc list-inside pl-3'>
                  <li>{t('account.start_billing')} {format.dateDdMmYyyy(account.subscription.date_started)}</li>
                  <li>{t('account.cancel_anytime')}</li>
                </ul>
              </div>
            </div>
            <Button
              customStyle='text-lg font-semibold !text-error-red px-6 py-3'
              type='submit'
              style='tertiary'
              loading={isLoading}
              disabled={isLoading}
              text={t('account.cancel_subscription')}
              onClick={handleCancel} />
          </div>
        </>
      )}
    </>
  );
}