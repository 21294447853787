import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { auth } from 'utils/firebase';
import { confirmPasswordReset, verifyPasswordResetCode } from 'firebase/auth';
import Input from 'components/Input';
import Button from 'components/Button';

export default function NewPassword() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [email, setEmail] = useState('');
  
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  // Obtener el código oob de los parámetros de la URL
  const searchParams = new URLSearchParams(location.search);
  const oobCode = searchParams.get('oobCode');

  useEffect(() => {
    if (oobCode) {
      verifyPasswordResetCode(auth, oobCode)
        .then((email) => {
          setEmail(email);
        })
        .catch((error) => {
          console.error('Error verifying reset code:', error);
          setError(t('global.password_reset.invalid_link'));
        });
    }
  }, [oobCode, t]);

  const onSubmit = async (data) => {
    if (data.password !== data.confirmPassword) {
      setError(t('global.password_reset.passwords_dont_match'));
      return;
    }

    setLoading(true);
    try {
      await confirmPasswordReset(auth, oobCode, data.password);
      navigate('/sign-in', { replace: true });
    } catch (error) {
      console.error('Error resetting password:', error);
      setError(t('global.password_reset.error'));
    } finally {
      setLoading(false);
    }
  };

  if (!oobCode) {
    return (
      <div className='flex flex-col items-center justify-center w-full h-full px-4'>
        <h1 className='text-[76px] font-thunder-bold' style={{ lineHeight: '68px' }}>
          ENLACE INVÁLIDO
        </h1>
        <div className='text-neutral-silver-200 text-lg mb-6 text-center'>
          El enlace para restablecer la contraseña es inválido o ha expirado.
        </div>
        <Button 
          type='button'
          customStyle='text-brand-gold font-bold'
          text={t('global.cancel')}
          onClick={() => navigate('/sign-in')} 
        />
      </div>
    );
  }

  return (
    <div className='flex flex-col items-center justify-center w-full h-full px-4'>
      <h1 className='text-[76px] font-thunder-bold' style={{ lineHeight: '68px' }}>
        NUEVA CONTRASEÑA
      </h1>
      {email && (
        <div className='flex flex-col items-center text-lg mb-6'>
          <span className='text-neutral-silver-200'>
            Establece una nueva contraseña para:
          </span>
          <span>{email}</span>
        </div>
      )}
      <div className='max-w-[480px] w-full'>
        <form onSubmit={handleSubmit(onSubmit)} className='w-full'>
          <div className='flex flex-col gap-6 w-full'>
            <Input
              type='password'
              label='Nueva contraseña'
              name='password'
              register={register}
              required={true}
              showHide={true}
              error={errors.password && t('global.required')}
              placeholder={t('global.write_here')} 
            />
            <Input
              type='password'
              label='Confirmar contraseña'
              name='confirmPassword'
              register={register}
              required={true}
              showHide={true}
              error={errors.confirmPassword && t('global.required')}
              placeholder={t('global.write_here')} 
            />
            {error && (
              <div className='text-error-red text-sm'>{error}</div>
            )}
            <Button 
              type='submit' 
              style='primary' 
              text='Confirmar'
              loading={loading}
              disabled={loading} 
            />
            <Button 
              type='button' 
              customStyle='text-brand-gold font-bold' 
              text={t('global.cancel')} 
              onClick={() => navigate('/sign-in')} 
            />
          </div>
        </form>
      </div>
    </div>
  );
} 