import { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { motion } from "framer-motion";
import { useCreateAccountMutation, useGetAccountQuery } from "store/api";
import Button from "components/Button";
import Input from "components/Input";
import ErrorMessage from "components/ErrorMessage";
import Modal from "components/Modal";
import TermsModal from "components/modals/TermsModal";
import MPModal from "components/modals/RedirectMPModal";
import spinner from "assets/images/icon-loading-claim.png";

export default function Setup() {
  const { t, i18n } = useTranslation();
  const [createUser, { isLoading: isLoadingAccount }] =
    useCreateAccountMutation();
  console.log("createUser", createUser);
  const {
    data: account,
    isLoading: isLoadingGetAccount,
    isError: isErrorGetAccount,
    error: accountError,
    refetch: refetchAccount,
  } = useGetAccountQuery({}, { refetchOnMountOrArgChange: true });

  const [usernameError, setUsernameError] = useState(null);
  const [mPSub, setMPSub] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [redirect, setredirect] = useState(false);
  const [step, setStep] = useState(0);
  const [openTerms, setOpenTerms] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm();

  const validate = {
    username: watch("username"),
    name: watch("name"),
    plan: watch("plan"),
    terms: watch("terms"),
  };

  useEffect(() => {
    if (accountError?.status === 403) {
      window.location.href = "/sign-in";
    }
  }, [accountError]);

  const handleFirstStep = async (data) => {
    setUsernameError(null);
    clearErrors("username");

    if (!account?.username) {
      try {
        const response = await createUser({
          type: "artist",
          data: {
            username: data.username,
            full_name: data.name,
            email: account?.email,
            login_method: account?.login_method,
          },
        });

        if ("error" in response) {
          if (
            response.error.data?.error?.some(
              (err) =>
                typeof err === "string" &&
                err.toLowerCase().includes("username already used")
            )
          ) {
            const errorMessage =
              i18n.language === "es"
                ? "El nombre de usuario ya existe. Elige uno diferente."
                : "Username already exists. Select a different one.";
            setUsernameError(errorMessage);
            setError(
              "username",
              {
                type: "manual",
                message: errorMessage,
              },
              { shouldFocus: true }
            );
            return;
          }
        }

        await refetchAccount();
      } catch (error) {
        console.error("Error inesperado:", error);
      }
    }
  };

  const handleCloseRedirect = () => {
    setredirect(false);
  };

  const stepOne = () => (
    <>
      <div className="flex flex-col items-center px-6">
        <h2>{t("setup.title")}</h2>
      </div>
      <div className="w-full max-w-[480px]">
        <form onSubmit={handleSubmit(handleFirstStep)}>
          <div className="flex flex-col gap-6 mb-6">
            <Input
              value={account?.email}
              type="email"
              name="email"
              label="Email"
              disabled
            />
            <Input
              type="text"
              name="username"
              label="Usuario"
              required
              autoComplete="off"
              noWhiteSpace
              register={register}
              error={errors.username?.message || usernameError}
            />
            <Input
              type="text"
              name="name"
              label="Nombre de artista"
              required
              autoComplete="off"
              register={register}
              error={errors.name && t("setup.required_field")}
            />
          </div>
          {errors.terms && (
            <div className="flex items-center justify-end h-14">
              <ErrorMessage
                show={errors.terms}
                message={t("setup.accept_terms")}
              />
            </div>
          )}
          <div className="flex items-center gap-3 mb-8">
            <input
              type="checkbox"
              name="terms"
              id="terms-and-conditions"
              {...register("terms", { required: true })}
            />
            <label htmlFor="terms-and-conditions">
              {t("setup.step_two.terms")}{" "}
              <span
                className="text-brand-gold hover:cursor-pointer"
                onClick={() => setOpenTerms(true)}
              >
                {t("setup.step_two.terms_link")}
              </span>
            </label>
          </div>
          <Button
            style="primary"
            type="submit"
            text={t("setup.continue")}
            disabled={
              !validate.username ||
              !validate.name ||
              !validate.terms ||
              isLoadingAccount
            }
            loading={isLoadingAccount}
          />
        </form>
        <Modal show={openTerms} setShow={setOpenTerms}>
          <TermsModal toggle={() => setOpenTerms(false)} />
        </Modal>
      </div>
    </>
  );

  const LoadingComponent = () => (
    <div className="flex justify-center items-center h-full">
      <img
        src={spinner}
        alt="Loading"
        width={40}
        height={40}
        className="animate-spin"
      />
      <span className="ml-2">Cargando...</span>
    </div>
  );

  if (isLoadingGetAccount) {
    return <LoadingComponent />;
  }

  if (isErrorGetAccount) {
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <h2 className="text-red-500 mb-4">
          Error al cargar los datos de la cuenta
        </h2>
        <Button
          style="primary"
          text="Reintentar"
          onClick={() => refetchAccount()}
        />
      </div>
    );
  }

  if (!account) {
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <h2 className="mb-4">No se encontraron datos de cuenta</h2>
        <Button
          style="primary"
          text="Volver al inicio"
          onClick={() => (window.location.href = "/")}
        />
      </div>
    );
  }

  if (account?.subscription?.plan && !mPSub) {
    return <Navigate to="/my-chest" replace />;
  }

  if (!account?.email_verified) {
    return (
      <motion.div
        initial={{ opacity: 0, y: -30 }}
        animate={{ opacity: 1, y: 0 }}
        className="flex flex-col gap-4 px-4"
      >
        <h1 className="text-[76px]" style={{ lineHeight: "68px" }}>
          {t("global.verify_email.title")}
        </h1>
        <div className="flex flex-col items-center text-lg mb-6">
          <span className="text-neutral-silver-200">
            {t("global.verify_email.email_sent_to")}
          </span>
          <span>{account?.email}</span>
        </div>
        <div className="text-neutral-silver-300 text-sm mb-3 text-center">
          {t("global.password_reset.tip")}
        </div>
        <div className="flex items-center justify-center">
          <a
            href="https://chestmusic.com/#contactanos"
            className="text-brand-gold h-10 md:h-auto hover:text-brand-bronze font-semibold text-lg py-1.5"
          >
            {t("global.verify_email.issues")}
          </a>
        </div>
      </motion.div>
    );
  }

  return (
    <div className="flex flex-col gap-8 items-center justify-center h-full pt-10 pb-10 md:px-[120px] md:py-20 w-full">
      {step === 0 ? stepOne() : <LoadingComponent />}
      <Modal show={redirect}>
        <MPModal
          handleClose={handleCloseRedirect}
          account={account}
          selectedPlan={selectedPlan}
          setMPSub={setMPSub}
        />
      </Modal>
    </div>
  );
}
