import { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isDesktop } from 'react-device-detect';
import { motion } from 'framer-motion';
import { useLazyGetTrackSourceQuery, useUpdateTrackPlayMutation } from 'store/api';
import { reset, playing as playingAction, play, next } from 'app/playlist';
import { useModal } from 'hooks/useModal';

import ProgressBar from 'components/player/ProgressBar';
import ProgressBarMobile from 'components/player/ProgressBarMobile';
import Controls from 'components/player/Controls';
import ControlsMobile from 'components/player/ControlsMobile';
import VolumeControls from 'components/player/VolumeControls';
import Track from 'components/player/Track';
import TrackMobile from 'components/player/TrackMobile';

import { ChevronDownIcon, EllipsisHorizontalIcon } from '@heroicons/react/24/outline';
import { closePlayer, openPlayer } from 'app/player';

export default function Player() {
  const dispatch = useDispatch();
  const { playlist, playing: isPlaying } = useSelector(state => state.playlist);
  const { user } = useSelector(state => state.auth);
  const [isCounted, setIsCounted] = useState(false);
  const [getTrackSource, getResult] = useLazyGetTrackSourceQuery();
  const [updateTrackPlay, updateResult] = useUpdateTrackPlayMutation();
  const { onOpen: openLimitModal } = useModal('PlayLimitModal');

  const [loop, setLoop] = useState(false);
  const [timeProgress, setTimeProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [trackList, setTrackList] = useState();
  const [open, setOpen] = useState(false);
  const [lastPlayed, setLastPlayed] = useState();
  const [playCount, setPlayCount] = useState(0);
  const [playLimit, setPlayLimit] = useState(null);
  const [hasStartedPlaying, setHasStartedPlaying] = useState(false);

  const audioRef = useRef();
  const progressBarRef = useRef();

  const toggleOpen = () => {
    setOpen(prev => !prev);
  }

  const onLoadedMetadata = () => {
    if (audioRef.current) {
      const seconds = audioRef.current.duration;
      setDuration(seconds);
      if (progressBarRef.current) {
        progressBarRef.current.max = seconds;
      }
    }
  }

  const updatePlayCount = useCallback(async () => {
    const currentTrack = playlist[0];
    if (!currentTrack) return;

    const isSharedLink = !!currentTrack.token;
    
    try {
      const result = await updateTrackPlay({
        anonymous: isSharedLink,
        id: trackList?.id,
        token: currentTrack.token
      });
      
      if (result.data) {
        const newCount = playCount + 1;
        setPlayCount(newCount);
        
        setTrackList(prevTrack => ({
          ...prevTrack,
          plays: newCount
        }));
        
        if (isSharedLink) {
          dispatch(playingAction({
            ...currentTrack,
            plays: newCount
          }));

          if (newCount >= currentTrack.play_limit) {
            if (audioRef.current) {
              audioRef.current.pause();
            }
            dispatch(playingAction({
              ...currentTrack,
              isPlaying: false,
              plays: newCount
            }));
          }
        }
      }
    } catch (error) {
      console.error('Error updating play count:', error);
      if (audioRef.current) {
        audioRef.current.pause();
      }
      dispatch(playingAction({
        ...currentTrack,
        isPlaying: false
      }));
    }
  }, [trackList?.id, playlist, playCount, dispatch, updateTrackPlay]);

  const checkPlayLimit = useCallback(() => {
    const currentTrack = playlist[0];
    if (!currentTrack) return true;

    const isSharedLink = !!currentTrack.token;
    
    if (isSharedLink) {
      return playCount >= currentTrack.play_limit;
    }

    return false;
  }, [playlist, playCount]);

  const handleTrackEnd = useCallback(async () => {
    if (!audioRef.current) return;
    
    setHasStartedPlaying(false);
    audioRef.current.currentTime = 0;
    
    const currentTrack = playlist[0];
    if (!currentTrack) return;

    if (currentTrack.token) {
      await updatePlayCount();
    }

    dispatch(playingAction({
      ...currentTrack,
      isPlaying: false
    }));
  }, [playlist, updatePlayCount, dispatch]);

  const handleTogglePlay = useCallback(() => {
    const currentTrack = playlist[0];
    if (!currentTrack) return;

    dispatch(playingAction({
      ...currentTrack,
      isPlaying: !currentTrack.isPlaying
    }));
  }, [playlist, dispatch]);

  useEffect(() => {
    if (trackList?.url) {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.load();
      }
    }
  }, [trackList?.url]);

  useEffect(() => {
    if (!audioRef.current || !trackList?.url) return;

    const audio = audioRef.current;
    const currentTrack = playlist[0];
    if (!currentTrack) return;

    const isSharedLink = !!currentTrack.token;

    const handlePlay = async () => {
      try {
        if (isSharedLink && checkPlayLimit()) {
          dispatch(playingAction({
            ...currentTrack,
            isPlaying: false
          }));
          return;
        }

        if (!hasStartedPlaying) {
          setHasStartedPlaying(true);
          await updatePlayCount();
        }
        
        if (!open) {
          setOpen(true);
        }

        await audio.play();
      } catch (error) {
        console.error('Error playing audio:', error);
        dispatch(playingAction({
          ...currentTrack,
          isPlaying: false
        }));
      }
    };

    if (currentTrack.isPlaying) {
      handlePlay();
    } else {
      audio.pause();
    }

    return () => {
      if (audio) {
        audio.pause();
      }
    };
  }, [playlist[0]?.isPlaying, trackList?.url, playCount, hasStartedPlaying, updatePlayCount, checkPlayLimit]);

  useEffect(() => {
    const currentTrack = playlist[0];
    if (!currentTrack) return;

    const isSharedLink = !!currentTrack.token;
    
    if (isSharedLink) {
      setPlayCount(currentTrack.plays || 0);
      setPlayLimit(currentTrack.play_limit || null);
      
      if (currentTrack.plays >= currentTrack.play_limit) {
        dispatch(playingAction({
          ...currentTrack,
          isPlaying: false
        }));
      }
    }
  }, [playlist]);

  useEffect(() => {
    let track = trackList;
    let currentTrack = playlist && playlist[0];

    if (currentTrack) {
      if (typeof track === 'object' && track?.id === currentTrack.id) {
        return;
      }

      setIsCounted(false);
      setPlayCount(currentTrack.plays || 0);
      setPlayLimit(currentTrack.play_limit || null);
      setHasStartedPlaying(false);
      setDuration(0);
      if (progressBarRef.current) {
        progressBarRef.current.value = 0;
      }
      
      if (currentTrack.audio) {
        setTrackList({
          url: currentTrack.audio,
          cover_url: currentTrack.cover,
          name: currentTrack.name,
          authors: currentTrack.authors,
          type: currentTrack.type,
          id: currentTrack.id,
          plays: currentTrack.plays,
          play_limit: currentTrack.play_limit
        });
      } else {
        getTrackSource(currentTrack.id)
          .then(({ data }) => {
            if (data) {
              setTrackList({
                url: data.url,
                cover_url: currentTrack.cover,
                name: currentTrack.name,
                authors: currentTrack.authors,
                type: currentTrack.type,
                id: currentTrack.id,
                plays: currentTrack.plays,
                play_limit: currentTrack.play_limit
              });
            }
          })
          .catch(error => {
            console.error('Error getting track source:', error);
            dispatch(playingAction({
              ...currentTrack,
              isPlaying: false
            }));
          });
      }
    } else {
      setTrackList(undefined);
    }
  }, [playlist]);

  useEffect(() => {
    setHasStartedPlaying(false);
  }, [trackList?.id]);

  useEffect(() => {
    if (!audioRef.current || !trackList?.url) return;

    const audio = audioRef.current;

    const handleLoadedMetadata = () => {
      const seconds = audio.duration;
      setDuration(seconds);
      if (progressBarRef.current) {
        progressBarRef.current.max = seconds;
      }
    };

    const handleEnded = () => {
      handleTrackEnd();
    };

    audio.addEventListener('loadedmetadata', handleLoadedMetadata);
    audio.addEventListener('ended', handleEnded);

    return () => {
      audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
      audio.removeEventListener('ended', handleEnded);
    };
  }, [trackList?.url, handleTrackEnd]);

  useEffect(() => {
    return () => {
      if (audioRef.current) {
        const audio = audioRef.current;
        audio.pause();
        audio.src = '';
        audio.load();
      }
    };
  }, []);

  useEffect(() => {
    if (!progressBarRef.current && audioRef.current) {
      progressBarRef.current = {
        value: 0,
        max: audioRef.current.duration || 0,
        style: {
          setProperty: () => {}
        }
      };
    }
  }, []);

  return (
    <>
      {trackList && playlist.length > 0 && (
        <>
          <audio
            src={trackList.url}
            ref={audioRef}
          />
          {playLimit !== null && playLimit > 0 && (
            <div className='fixed bottom-[102px] right-0 left-0 inset-x-0 max-w-max mx-auto z-50'>
              <motion.div
                initial={{ opacity: 0, y: 40 }}
                animate={{ opacity: 1, y: 0 }}
                className='px-6 py-3 bg-neutral-silver-600 rounded-t-xl'>
                <span className='text-brand-gold'>{playLimit - playCount}</span> of {playLimit} plays remaining
              </motion.div>
            </div>
          )}
          {isDesktop ? (
            <motion.div
              className='fixed bottom-0 left-0 right-0 bg-neutral-silver-700 px-6 py-4 flex items-center gap-6 z-50'
              initial={{ height: 0 }}
              animate={{ height: 'auto' }}
              exit={{
                height: 0,
                transition: { delay: 0.7, duration: 1, ease: 'easeIn' }
              }}>
              <Track {...{
                currentTrack: trackList,
                audioRef,
                setDuration,
                progressBarRef
              }} />
              <div className='grow flex flex-col items-center justify-center gap-1.5'>
                <Controls {... {
                  audioRef,
                  progressBarRef,
                  duration,
                  setTimeProgress,
                  setLoop,
                  loop
                }} />
                <ProgressBar {...{
                  progressBarRef,
                  audioRef,
                  timeProgress,
                  duration
                }} />
              </div>
              <VolumeControls {...{
                audioRef
              }} />
            </motion.div>
          ) : (
            <>
              <div className={`fixed bottom-0 left-0 right-0 z-50 ${open ? 'h-screen bg-neutral-black' : ''}`}>
                <div className={`${open ? 'audio-player-mobile-open' : 'audio-player-mobile'}`}>
                  {!open ? (
                    <div className='flex flex-row items-center bg-neutral-silver-700 px-4 py-3' onClick={toggleOpen}>
                      <div 
                        className='w-10 h-10 min-w-[40px] bg-cover rounded mr-3 cursor-pointer' 
                        style={{ backgroundImage: `url(${trackList.cover_url || 'https://cdn.chestmusic.com/cover-default.jpg'})` }}
                        onClick={handleTogglePlay}
                      />
                      <TrackMobile {...{
                        currentTrack: trackList,
                        audioRef,
                        setDuration,
                        progressBarRef
                      }} />
                      <ControlsMobile {... {
                        audioRef,
                        progressBarRef,
                        duration,
                        setTimeProgress,
                        setLoop,
                        loop
                      }} />
                    </div>
                  ) : (
                    <>
                      <div className='bg-neutral-silver-700 rounded-3xl mb-3 gap-5 flex flex-col p-4'>
                        <div className='flex flex-row justify-between'>
                          <button type='button' className='p-2.5' onClick={toggleOpen}>
                            <ChevronDownIcon className='h-6 w-6 text-white' />
                          </button>
                        </div>
                        <TrackMobile {...{
                          currentTrack: trackList,
                          audioRef,
                          setDuration,
                          progressBarRef,
                          open
                        }} />
                      </div>
                      <div className='bg-neutral-silver-600 px-5 pb-5 pt-4 rounded-3xl flex flex-col gap-1.5'>
                        <div className='flex items-center justify-center gap-1.5'>
                          <Controls {... {
                            audioRef,
                            progressBarRef,
                            duration,
                            setTimeProgress,
                            setLoop,
                            loop
                          }} />
                        </div>
                        <ProgressBarMobile {...{
                          progressBarRef,
                          audioRef,
                          timeProgress,
                          duration,
                          open
                        }} />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}