import Input from 'components/Input';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import { auth, actionCodeSettings } from 'utils/firebase';
import { sendPasswordResetEmail } from 'firebase/auth';
import { useForm } from 'react-hook-form';
import { NavLink, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { motion } from 'framer-motion';

export default function PasswordReset() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [emailSentTo, setEmailSentTo] = useState('');
  
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      setEmailSentTo(data.email);
      await sendPasswordResetEmail(auth, data.email, actionCodeSettings);
      setEmailSent(true);
    } catch (error) {
      console.error('Error sending password reset email:', error);
      if (error.code === 'auth/invalid-email') {
        setError(t('global.password_reset.invalid_email'));
      } else {
        setError(t('global.password_reset.error'));
      }
    } finally {
      setLoading(false);
    }
  };

  if (emailSent) {
    return (
      <div className='flex flex-col items-center justify-center w-full h-full px-4'>
        <h1 className='text-[76px] font-thunder-bold' style={{ lineHeight: '68px' }}>
          REVISA TU CORREO ELECTRÓNICO
        </h1>
        <div className='flex flex-col items-center text-lg mb-6'>
          <span className='text-neutral-silver-200'>
            Se envió un correo electrónico para restablecer la contraseña a:
          </span>
          <span>{emailSentTo}</span>
        </div>
        <div className='text-neutral-silver-300 text-sm mb-3 text-center'>
          Si no puede encontrar el correo electrónico, asegúrese de revisar su carpeta de correo no deseado.
        </div>
        <Button 
          type='button' 
          customStyle='text-brand-gold font-bold' 
          text={t('global.cancel')} 
          onClick={() => navigate('/sign-in')} 
        />
      </div>
    );
  }

  return (
    <div className='flex flex-col items-center justify-center w-full h-full'>
      <h1 className='text-[76px]'>{t('global.password_reset.title')}</h1>
      <p className='text-neutral-silver-200 mb-6'>{t('global.password_reset.description')}</p>
      <div className='max-w-[480px] w-full'>
        <form onSubmit={handleSubmit(onSubmit)} className='w-full'>
          <div className='flex flex-col gap-6 w-full'>
            <Input
              type='email'
              label={t('global.email')}
              name='email'
              register={register}
              required={true}
              error={errors.email && t('global.required')}
              placeholder={t('global.write_here')} 
            />
            {error && (
              <div className='text-error-red text-sm'>{error}</div>
            )}
            <Button 
              type='submit' 
              style='primary' 
              text={t('global.password_reset.send')} 
              loading={loading}
              disabled={loading} 
            />
            <Button 
              type='button' 
              customStyle='text-brand-gold font-bold' 
              text={t('global.cancel')} 
              onClick={() => navigate('/sign-in')} 
            />
          </div>
        </form>
      </div>
    </div>
  );
}
