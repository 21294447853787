import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetAccountQuery, useGetPlansQuery } from 'store/api';
import { Link } from 'react-router-dom';
import { useDeleteSubscriptionMutation, useCreateSubscriptionMutation } from 'store/api';
import { store } from 'app/store';
import { persistStore } from 'redux-persist';
import Breadcrumb from 'components/Breadcrumb';
import Button from 'components/Button';
import Card from 'components/plan-details/Card';
import stripe from 'assets/images/logo-stripe.svg';
import mp from 'assets/images/logo-mp.svg';
import { TagIcon } from "@heroicons/react/24/outline";
import MPModal from 'components/modals/RedirectMPModal';
import Modal from 'components/Modal';
import PromoCodeModal from 'components/modals/PromoCodeModal';

export default function Plan() {
  const { t, i18n } = useTranslation();
  const classIcon = 'h-7 w-7 text-brand-gold'
  const paths = [
    { name: t('global.profile'), link: '/profile' },
    { name: t('global.account'), link: '/profile/account' },
    { name: t('account.update_plan') }
  ];
  const [promoCode, setPromoCode] = useState(undefined)
  const { data: plans, refetch: refetchPlans, isLoading: isLoadingPlans } = useGetPlansQuery(promoCode, { refetchOnMountOrArgChange: true });
  const [deleteSubscription, { isLoading: isLoadingDelete }] = useDeleteSubscriptionMutation();
  const [createSubscription, { isLoading: isLoadingSubscription, isSuccess: isSuccessSubscription }] = useCreateSubscriptionMutation();
  const {  data: account, isSuccess } = useGetAccountQuery({}, { refetchOnMountOrArgChange: true });
  const [redirect, setredirect] = useState(false)
  const [selectedPlan, setSelectedPlan] = useState('');
  const [showCodeInput, setShowCodeInput] = useState(false);

  const handlePlan = (plan) => {
    setSelectedPlan(plan);
  }

  const handlePromoCodeChange = async (event) => {
    event.preventDefault();
    setShowCodeInput(false)
  };  

  const handleCancel = async () => {
    const result = await deleteSubscription(account.subscription.id);

    if ('error' in result) {
      console.log(result);
    } else {
      window.location.href = '/my-chest';
    }
  }

  const handleMercadoPago = async () =>{
    setredirect(true)
  }

  const handleCloseRedirect = async () =>{
    setredirect(false)
  }

  const handleConfirm = async () => {
    if(plans.plans[0].payment_method === 'mercadopago'){
      handleMercadoPago()
    } else {
      const resultSuscription = await createSubscription({ plan: selectedPlan }).unwrap();
  
      if ('error' in resultSuscription) {
        console.log(resultSuscription);
      } else {
        window.location.href = resultSuscription.gateway_url;
      }
    }
  }

  useEffect(() => {
    if (plans?.plans) {
      // Seleccionar el plan anual por defecto
      const annualPlan = plans.plans.find(p => p.billing_frequency === 12);
      setSelectedPlan(annualPlan?.id || plans.plans[0].id);
    }
  }, [plans]);

  useEffect(() => {
    if (isSuccessSubscription) {
      persistStore(store).purge();
    }
  }, [isSuccessSubscription]);

  const planOption = (plan) => {
    if (plan) {
      return (
        <Card
          key={plan.id}
          plan={plan}
          handlePlan={handlePlan}
          selectedPlan={selectedPlan}
          plans={plans}
        />
      );
    }
    return null;
  }
  
  if (!isSuccess) return null
  
  // Ordenar los planes: anual primero, mensual después
  const sortedPlans = plans?.plans ? [...plans.plans].sort((a, b) => b.billing_frequency - a.billing_frequency) : [];
  
  return (
    <>
      <Modal show={showCodeInput}>
        <PromoCodeModal
          handleClose={() => setShowCodeInput(false)}
          promoCode={promoCode}
          setPromoCode={(e) => setPromoCode(e)}
          handleSubmit={handlePromoCodeChange}
        />
      </Modal>
      {!isLoadingPlans && (
        <div className='flex flex-col gap-8 h-full pt-10 pb-10 px-3 md:px-[120px] md:py-20'>
          <div className='flex flex-col items-center gap-5'>
            <Breadcrumb className='px-3 md:px-0' items={paths} />
            <h2 className='text-5xl'>
              {t('account.choose_plan')}
            </h2>
            <div className='flex flex-col gap-6 max-w-[480px] w-full mb-6'>
              {sortedPlans.map(plan => planOption(plan))}
            </div>
            <div className='w-full max-w-[480px] flex flex-col gap-3'>
              <span className='font-bold'>{t('account.payment_method')}</span>
              <div className='bg-neutral-black rounded-2xl px-6 py-4 flex flex-row items-center'>
                <div className='flex flex-col grow'>
                  <span className='text-lg'>{t('account.credit_debit')}</span>
                  <span className='text-sm text-neutral-silver-300'>
                    {t('account.redirected_to')} {plans?.plans[0].payment_method === 'mercadopago' ? 'Mercado Pago' : 'Stripe'}
                  </span>
                </div>
                <div>
                  <img 
                    src={plans?.plans[0].payment_method === 'mercadopago' ? mp : stripe}
                    alt={plans?.plans[0].payment_method === 'mercadopago' ? 'Mercado Pago' : 'Stripe'} 
                    width={104} 
                    height={36} />
                </div>
              </div>
            </div>
            <div className='flex flex-col gap-3 max-w-[480px] w-full mb-6'>
              <Button
                style='primary'
                type='submit'
                text={t('global.confirm')}
                disabled={selectedPlan === account?.subscription?.plan || !selectedPlan}
                loading={isLoadingDelete || isLoadingSubscription}
                onClick={handleConfirm} />
              <Link to='/profile/account/subscription/' className='btn btn-tertiary'>
                {t('global.back')}
              </Link>
              {account?.has_subscription && (
                <Button
                  customStyle='text-lg font-semibold text-error-red px-6 py-3'
                  type='submit'
                  text={t('account.cancel_subscription')}
                  onClick={handleCancel} />
              )}
            </div>
          </div>
          <Modal show={redirect}>
              <MPModal handleClose={handleCloseRedirect} account={account} selectedPlan={selectedPlan} promoCode={promoCode} ></MPModal>
          </Modal>
        </div>
      )}
    </>
  );
}