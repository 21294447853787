// import i18next from 'i18next';
// import { initReactI18next } from 'react-i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';
// import HttpApi from 'i18next-http-backend';

// i18next
//   .use(initReactI18next)
//   .use(LanguageDetector)
//   .use(HttpApi)
//   .init({
//     debug: false,
//     fallbackLng: 'en',
//     backend: {
//       loadPath: '/locales/{{lng}}/{{ns}}.json'
//     }
//   });

import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    debug: true, // Temporalmente activamos el debug para ver si hay errores
    fallbackLng: "en",
    supportedLngs: ["en", "es"],
    detection: {
      order: [
        "querystring",
        "cookie",
        "localStorage",
        "sessionStorage",
        "navigator",
        "htmlTag",
        "path",
        "subdomain"
      ],
      caches: ["localStorage", "cookie"],
      cookieMinutes: 160,
      cookieDomain: 'localhost'
    },
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
    interpolation: {
      escapeValue: false
    }
  });

export default i18next;
