import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

export default function Card({ plan, handlePlan, selectedPlan, register, plans }) {
    const { t } = useTranslation()
    
    if (!plan?.id || !plan?.pricing_data) {
        console.warn('Card: Plan object is missing required properties')
        return null
    }

    const desc = plan.name?.split(' ')?.pop() || ''
    const isReferralOrInfo = ["referral", "info"].includes(plans?.info?.type)
    const isPromo = plan.type === 'promo'
    
    // Función para formatear precios con coma decimal y punto para miles
    const formatPrice = (price) => {
        return price.toLocaleString('es-AR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).replace('.', ',')
    }

    const features = [
        'Almacenamiento seguro infinito',
        'Acceso ilimitado a las features',
        'Membresía Club Chest'
    ]

    // Definir los precios
    const prices = {
        monthly: 4999.00,
        annual: 47990.00,
        annualMonthly: 3999.17
    }

    const currentPrice = plan.billing_frequency === 12 ? prices.annual : prices.monthly
    const displayPrice = plan.billing_frequency === 12 ? prices.annualMonthly : prices.monthly

    return (
        <div
            className='account-plan'
            key={plan.id}
            onClick={() => handlePlan?.(plan.id)}>
            <input
                type='radio'
                id={plan.id}
                value={plan.id}
                name='plan'
                checked={plan.id === selectedPlan}
                {...(register && register('plan', { required: true }))} />
            <label htmlFor={plan.id}>
                <div className='flex items-center gap-3 py-3 max-w-md grow justify-between rounded-xl'>
                    <div className='text-lg font-semibold self-center'>
                        {plan.billing_frequency === 12 ? 'Suscripción anual' : 'Suscripción mensual'}
                    </div>
                </div>
                <ul className='list-disc pl-5 text-sm mb-4 space-y-1 text-neutral-silver-300'>
                    {features.map((feature, index) => (
                        <li key={index}>{feature}</li>
                    ))}
                </ul>
                <div className='mt-3 flex gap-1 items-baseline'>
                    <span className='font-thunder text-2xl uppercase'>
                        ARS
                    </span>
                    <span className='font-thunder text-4xl'>
                        {formatPrice(displayPrice)}
                    </span>
                    <span>
                        {plan.billing_frequency === 12 ? '/ promedio al mes' : '/ por mes'}
                    </span>
                </div>
                {plan.billing_frequency === 12 && (
                    <div className='text-sm mt-2'>
                        Pago anual de ARS {formatPrice(currentPrice)}
                    </div>
                )}
            </label>
        </div>
    )
}

Card.propTypes = {
    plan: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        type: PropTypes.string,
        billing_frequency: PropTypes.number.isRequired,
        free_trial_duration: PropTypes.number,
        pricing_data: PropTypes.shape({
            currency: PropTypes.string.isRequired,
            price: PropTypes.number.isRequired,
            regular_price: PropTypes.number
        }).isRequired
    }).isRequired,
    handlePlan: PropTypes.func,
    selectedPlan: PropTypes.string,
    register: PropTypes.func,
    plans: PropTypes.shape({
        info: PropTypes.shape({
            type: PropTypes.string
        })
    })
};
 