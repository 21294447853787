/* shuffle button disabled */

import { useRef, useCallback, useEffect } from 'react';

import { ReactComponent as RepeatIcon } from 'assets/images/icon-repeat.svg';
import { ReactComponent as NextIcon } from 'assets/images/icon-next.svg';
import { ReactComponent as PreviousIcon } from 'assets/images/icon-previous.svg';
import { ReactComponent as ShuffleIcon } from 'assets/images/icon-shuffle.svg';
import { ReactComponent as PlayIcon } from 'assets/images/icon-play.svg';
import { ReactComponent as PauseIcon } from 'assets/images/icon-pause.svg';
import { useDispatch, useSelector } from 'react-redux';
import { play, next, previous, playing } from 'app/playlist';

export default function Controls({
  audioRef,
  progressBarRef,
  duration,
  setTimeProgress,
  setLoop,
  loop
}) {
  const playAnimationRef = useRef();
  const dispatch = useDispatch();
  const playlist = useSelector((state) => state.playlist.playlist);

  const repeat = useCallback(() => {
    if (!audioRef.current) return;

    try {
      const currentTime = audioRef.current.currentTime;
      setTimeProgress(currentTime);
  
      if (progressBarRef.current) {
        progressBarRef.current.value = currentTime;
        progressBarRef.current.style.setProperty(
          '--range-progress',
          `${(progressBarRef.current.value / duration) * 100}%`
        );
      }
        
      playAnimationRef.current = requestAnimationFrame(repeat);
    } catch (error) {
      console.error('Error in repeat:', error);
      cancelAnimationFrame(playAnimationRef.current);
    }
  }, [audioRef, duration, progressBarRef, setTimeProgress]);

  const togglePlayPause = () => {
    if (!audioRef.current?.src) return;
    dispatch(play());
  }

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 32 && 
          event.target.tagName !== 'INPUT' && 
          event.target.tagName !== 'TEXTAREA') {
        event.preventDefault();
        if (audioRef.current?.src) {
          dispatch(play());
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [dispatch]);

  const toggleLoop = () => {
    setLoop(prev => !prev);
  }

  const skipForward = () => {
    if (playlist[0]?.token) return;
    dispatch(next());
  }

  const skipBackward = () => {
    if (playlist[0]?.token) return;
    dispatch(previous());
  }

  useEffect(() => {
    if (!audioRef.current?.src) return;
    
    playAnimationRef.current = requestAnimationFrame(repeat);

    return () => {
      if (playAnimationRef.current) {
        cancelAnimationFrame(playAnimationRef.current);
      }
    };
  }, [repeat, audioRef.current?.src]);

  return (
    <>
      <div className='flex items-center'>
        <button type='button' className='p-2 mr-2 disabled:opacity-30' disabled>
          <ShuffleIcon />
        </button>
        <button 
          type='button' 
          onClick={skipBackward}
          className='p-2 player-controls'
          disabled={!audioRef.current?.src || playlist[0]?.token}>
          <PreviousIcon />
        </button>
        <button 
          type='button' 
          onClick={togglePlayPause} 
          className='player-controls'
          disabled={!audioRef.current?.src}>
          {playlist[0]?.isPlaying ?
            <PauseIcon width={40} height={40} /> :
            <PlayIcon width={40} height={40} />
          }
        </button>
        <button 
          type='button' 
          onClick={skipForward}
          className='p-2 player-controls'
          disabled={!audioRef.current?.src || playlist[0]?.token}>
          <NextIcon />
        </button>
        <button
          type='button'
          className={`p-2 ml-2 ${loop ? 'player-controls-active' : 'player-controls'}`}
          onClick={toggleLoop}
          disabled={!audioRef.current?.src}>
          <RepeatIcon />
        </button>
      </div>
    </>
  );
}